<template>
	<div class="line-graph">
		<canvas ref="graph"></canvas>
	</div>
</template>

<script>
import Chart from 'chart.js'
import moment from 'moment'

export default {
	name: 'line-graph',
	props: {
		data: {
			type: Array,
			default: function() {
				return []
			}
		},
		append: {
			type: String,
			default: ''
		},
		prepend: {
			type: String,
			default: ''
		}
	},
	computed: {
		dataset() {
			return {
				labels: this.data.map(item => this.parseDate(item.date)),

				datasets: [
					{
						data: this.data.map(item => item.value),
						borderColor: '#2553CD',
						borderWidth: 1,
						lineTension: 0,
						pointBorderColor: '#2553CD',
						pointBackgroundColor: '#2553CD',
						pointRadius: 2,
						fill: false
					}
				]
			}
		}
	},
	data() {
		return {
			chart: null,
			options: {
				scales: {
					xAxes: [
						{
							display: true
						}
					],
					yAxes: [
						{
							display: true,
							ticks: {
								beginAtZero: true
							}
						}
					]
				},
				legend: {
					display: false
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							return (
								this.prepend +
								// eslint-disable-next-line
								data['datasets'][0]['data'][
									tooltipItem['index']
								]
							)
						}
					}
				},
				responsive: true,
				maintainAspectRatio: false
			}
		}
	},
	methods: {
		reload() {
			this.chart.destroy()
			this.chart = new Chart(this.$refs.graph, {
				type: 'line',
				data: this.dataset,
				options: this.options
			})
		},
		parseDate(date) {
			return moment.utc(date).format('MM/DD/YYYY')
		}
	},
	mounted() {
		this.chart = new Chart(this.$refs.graph, {
			type: 'line',
			data: this.dataset,
			options: this.options
		})
	},
	watch: {
		dataset() {
			this.reload()
		}
	}
}
</script>

<style lang="scss">
.line-graph {
	padding: 10px 20px;
	height: 300px;
}
</style>
